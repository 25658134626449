import * as i from 'types';

import { calculateSolarPanelsAmount } from './calculateSolarPanels';

export const calculateSubsidyInfra = (answers?: i.Answers) => {
  return Object.values(calculateSubsidyInfraIndiv(answers)).reduce(
    (acc, val) => acc + val,
    0,
  );
};

export const calculateSubsidyInfraIndiv = (answers?: i.Answers) => {
  const subsidyPerInfra = {
    solarPanels: 0,
  };

  const amountOfSolarPanels = calculateSolarPanelsAmount(answers);

  // You will only receive ISDE subsidy when you have more than 40 solar panels
  // The subsidy for one solar panel is 40,625 euros per solar panel
  if (amountOfSolarPanels > 39) {
    subsidyPerInfra.solarPanels = amountOfSolarPanels * 40.625;
  }

  return subsidyPerInfra;
};
