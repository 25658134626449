import * as i from 'types';
import * as React from 'react';
import { FieldError } from 'react-hook-form';

import { useQuestionnaireContext } from 'modules/questionnaire';
import { transformQuestionId, validation } from 'services';

import { Label } from 'common/typography';
import {
  FleetRangeCarImage,
  FleetRangeInput,
  FleetRangeSelectorOptionContainer,
  FleetRangeSelectorSubTitle,
} from './styled';

export const FleetRangeSelectorOption: React.FC<
  FleetRangeSelectorOptionProps
> = ({ car, questionId }) => {
  const [error, setError] = React.useState<FieldError>();
  const { answers, updateAnswer } = useQuestionnaireContext();
  const answer = answers?.[transformQuestionId(questionId)] as i.FleetInput;
  const sizeSelector = answer?.[car.comparison];

  const onChange = (value: number, car: i.Car) => {
    const values: VehicleType = {};

    answers?.fleet &&
      Object.keys(answers.fleet).forEach((vehicle) => {
        const includesVehicle = vehicle.startsWith(car.vehicle);
        if (includesVehicle) {
          values[vehicle] = value || 0;
        }
      });

    updateAnswer({
      ...answers,
      [questionId]: {
        ...(answers?.[transformQuestionId(questionId)] as i.MultipleAnswer),
        ...values,
      },
    });
  };

  const onBlur = () => {
    const value = Number(sizeSelector);
    const { minValue, maxValue } = validation.fleetRange;

    if (value < minValue.value) {
      setError({ type: 'error', message: minValue.message });
    } else if (value > maxValue.value) {
      setError({ type: 'error', message: maxValue.message });
    } else {
      setError(undefined);
    }
  };

  return (
    <FleetRangeSelectorOptionContainer
      key={car.model}
      isActive={Boolean(sizeSelector && sizeSelector > 0)}
      isFlex
    >
      <div>
        <Label>
          {car.vehicle === 'car' ? "Personenauto('s)" : 'Bestelwagen(s)'}
        </Label>
        <FleetRangeSelectorSubTitle newSize={2} noMargin>
          Gemiddeld km per jaar
        </FleetRangeSelectorSubTitle>
        <FleetRangeInput
          type="number"
          name={car.comparison}
          placeholder={answers?.userType === 'consumer' ? '12.000' : '30.000'}
          error={error}
          defaultValue={sizeSelector}
          onChange={(e) => onChange(parseInt(e.target.value), car)}
          onBlur={onBlur}
        />
      </div>
      <FleetRangeCarImage src={car.image?.file.url} alt={car.model} />
    </FleetRangeSelectorOptionContainer>
  );
};

type FleetRangeSelectorOptionProps = {
  car: i.Car;
  questionId: i.QuestionIds;
};

type VehicleType = {
  [car: string]: number;
};
