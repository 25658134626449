import * as i from 'types';

import {
  calculateInfrastructureInvestments,
  calculateTotalCostsIndiv,
  calculateFleetInvestments,
  calculateOneOffInvestment,
  calculateTaxBenefits,
  calculateTaxBenefitsIndiv,
  calculateInsurance,
  calculateInsuranceIndiv,
  calculateMaintenance,
  calculateTaxes,
  calculateVariableCosts,
  calculateFleetInvestmentsIndiv,
  calculateTotalCosts,
  calculateInfrastructureInvestmentsIndiv,
  calculateInfrastructureBenefits,
  calculateFleetBenefits,
  calculateLease,
  calculateLeaseIndiv,
  calculateMaintenanceIndiv,
  calculateTaxesIndiv,
  calculateEmissions,
  calculateAllCarsEmissions,
  calculateSubsidyInfraIndiv,
  calculateSubsidyInfra,
} from 'services';
import { calculateSolarPanels } from './calculateSolarPanels';

export const calculateAllCosts = (
  cars: GatsbyTypes.ContentfulCars[],
  variables: GatsbyTypes.ContentfulVariables,
  period: i.Period,
  answers?: i.Answers,
) => {
  return {
    total: calculateTotalCosts(cars, variables, period, answers),
    totalIndiv: calculateTotalCostsIndiv(cars, variables, period, answers),
    oneOff: calculateOneOffInvestment(cars, variables, answers),
    fleet: calculateFleetInvestments(cars, answers),
    fleetIndiv: calculateFleetInvestmentsIndiv(cars, variables, answers),
    infra: calculateInfrastructureInvestments(variables, answers),
    infraIndiv: calculateInfrastructureInvestmentsIndiv(variables, answers),
    subsidyInfra: calculateSubsidyInfra(answers),
    subsidyInfraIndiv: calculateSubsidyInfraIndiv(answers),
    taxBenefit: calculateTaxBenefits(cars, variables, answers),
    taxBenefitIndiv: calculateTaxBenefitsIndiv(cars, variables, answers),
    taxFleet: calculateFleetBenefits(cars, variables, answers),
    taxInfra: calculateInfrastructureBenefits(variables, answers),
    lease: calculateLease(cars, period, answers),
    leaseIndiv: calculateLeaseIndiv(cars, period, answers),
    insurance: calculateInsurance(cars, period, answers),
    insuranceIndiv: calculateInsuranceIndiv(cars, period, answers),
    maintenance: calculateMaintenance(cars, period, answers),
    maintenanceIndiv: calculateMaintenanceIndiv(cars, period, answers),
    petrolTax: calculateTaxes(cars, period, answers),
    petrolTaxIndiv: calculateTaxesIndiv(cars, period, answers),
    variable: calculateVariableCosts(variables, period, answers),
    evEmissions: calculateEmissions(cars, variables, 'Ev', answers),
    petrolEmissions: calculateEmissions(cars, variables, 'Pet', answers),
    allCarsEmissions: calculateAllCarsEmissions(cars, answers),
    solarPanels: calculateSolarPanels(answers),
  };
};
