import * as RadixTooltip from '@radix-ui/react-tooltip';
import { Paragraph } from 'common/typography';
import styled, { keyframes } from 'styled-components';

import TooltipSvg from 'vectors/icons/tooltip.svg';

const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const TooltipIcon = styled(TooltipSvg)`
  width: 16px;
  height: 16px;
`;

export const TooltipButton = styled.button`
  display: inline-block;
  place-content: center;
  vertical-align: middle;
  width: fit-content;
  padding: 0 0 0 8px;
  background-color: transparent;
  border: none;
`;

export const TooltipContent = styled(RadixTooltip.Content)`
  max-width: 300px;
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: 0px 4px 12px 0px rgba(65, 65, 65, 0.3);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  user-select: none;

  &[data-state='delayed-open'][data-side='top'] {
    animation-name: ${slideDownAndFade};
  }
  &[data-state='delayed-open'][data-side='right'] {
    animation-name: ${slideLeftAndFade};
  }
  &[data-state='delayed-open'][data-side='bottom'] {
    animation-name: ${slideUpAndFade};
  }
  &[data-state='delayed-open'][data-side='left'] {
    animation-name: ${slideRightAndFade};
  }
`;

export const TooltipParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.gray['800']};
`;

export const TooltipArrow = styled(RadixTooltip.Arrow)`
  fill: ${({ theme }) => theme.colors.white};
`;
