import * as i from 'types';

import {
  calculateOneOffInvestment,
  calculateTaxBenefits,
  calculateMaintenanceIndiv,
  calculateFixedCosts,
  calculateVariableCosts,
  calculateFleetInvestmentsIndiv,
  calculateTaxBenefitsIndiv,
  calculateLeaseIndiv,
  calculateInsuranceIndiv,
  calculateTaxesIndiv,
  calculateSubsidyInfra,
} from 'services';

export const calculateTotalCosts = (
  cars: GatsbyTypes.ContentfulCars[],
  variables: GatsbyTypes.ContentfulVariables,
  period: i.Period,
  answers?: i.Answers,
) => {
  const investment = calculateOneOffInvestment(cars, variables, answers);
  const subsidyInfra = calculateSubsidyInfra(answers);
  const taxBenefit = calculateTaxBenefits(cars, variables, answers);
  const fixedCosts = calculateFixedCosts(cars, variables, period, answers);
  const variableCosts = calculateVariableCosts(variables, period, answers);

  const depreciationTime = !answers?.depreciation
    ? 5
    : parseInt(answers?.depreciation);
  const subsidiesAndTaxes = taxBenefit + subsidyInfra;
  const multiplier = period === 'month' ? 12 : 1;
  const minusPeriod = depreciationTime * multiplier;
  const subtotalEv = investment.evPrice / minusPeriod;
  const totalEv = subtotalEv + fixedCosts.evPrice + variableCosts.evPrice;
  const subtotalPetrol = investment.petrolPrice / minusPeriod;
  const totalPetrol =
    subtotalPetrol + fixedCosts.petrolPrice + variableCosts.petrolPrice;

  return {
    ev: {
      oneOff: investment.evPrice, // Cars and infrastructure
      oneOffMinusSubsidiesAndTaxes: investment.evPrice - subsidiesAndTaxes,
      subsidyAndTaxes: taxBenefit + subsidyInfra, // Subsidies and tax benefits
      fixed: fixedCosts.evPrice, // Lease, insurance and maintenance Costs
      variable: variableCosts.evPrice, // Price for charging
      total: totalEv,
      totalMinusSubsidiesAndTaxes: totalEv - subsidiesAndTaxes / minusPeriod,
    },
    petrol: {
      oneOff: investment.petrolPrice, // Cars and infrastructure
      // Petrol cars don't receive subsidies and taxes
      oneOffMinusSubsidiesAndTaxes: investment.petrolPrice - 0,
      subsidyAndTaxes: 0, // Subsidies and tax benefits
      fixed: fixedCosts.petrolPrice, // Lease, insurance and maintenance Costs
      variable: variableCosts.petrolPrice, // Price for charging
      total: totalPetrol,
      // Petrol cars don't receive subsidies and taxes
      totalMinusSubsidiesAndTaxes: totalPetrol - 0,
    },
  };
};

export const calculateTotalCostsIndiv = (
  cars: GatsbyTypes.ContentfulCars[],
  variables: GatsbyTypes.ContentfulVariables,
  period: i.Period,
  answers?: i.Answers,
) => {
  const evPrice = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };
  const petrolPrice = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };

  if (!answers)
    return {
      evPrice,
      petrolPrice,
    };

  // EV: (Investmentprice - taxBenedit) / (depreciationTime * multiplier) + fixedCosts.evPrice + variableCosts.evPrice;
  // Petrol: Investmentprice / (depreciationTime* multiplier) + fixedCosts.petrolPrice + variableCosts.petrolPrice;
  const depreciationTime = !answers?.depreciation
    ? 5
    : parseInt(answers?.depreciation);
  const multiplier = period === 'month' ? 12 : 1;
  const fleetIndiv = calculateFleetInvestmentsIndiv(cars, variables, answers);
  const benefitIndiv = calculateTaxBenefitsIndiv(cars, variables, answers);
  const leaseIndiv = calculateLeaseIndiv(cars, period, answers);
  const insuranceIndiv = calculateInsuranceIndiv(cars, period, answers);
  const maintenanceIndiv = calculateMaintenanceIndiv(cars, period, answers);
  const taxesIndiv = calculateTaxesIndiv(cars, period, answers);
  const variableCosts = calculateVariableCosts(variables, period, answers);

  const { fleet } = answers;

  if (fleet) {
    (Object.entries(fleet) as [i.KeyOfFleetInput, number][]).forEach(
      ([key, value]) => {
        const evInvest = fleetIndiv.evPrice[key];
        const evBenefit = benefitIndiv[key];
        const evLease = leaseIndiv.evPrice[key];
        const evInsurance = insuranceIndiv.evPrice[key];
        const evMaintenance = maintenanceIndiv.evPrice[key];
        const evVariable = variableCosts.rangeAmount.ev[key];

        const petrolInvest = fleetIndiv.petrolPrice[key];
        const petrolLease = leaseIndiv.petrolPrice[key];
        const petrolInsurance = insuranceIndiv.petrolPrice[key];
        const petrolMaintenance = maintenanceIndiv.petrolPrice[key];
        const petrolTaxes = taxesIndiv[key];
        const petrolVariable = variableCosts.rangeAmount.petrol[key];

        evPrice[key] =
          (evInvest - evBenefit) / (depreciationTime * multiplier) +
          evLease +
          evInsurance +
          evMaintenance +
          evVariable;

        petrolPrice[key] =
          petrolInvest / (depreciationTime * multiplier) +
          petrolLease +
          petrolInsurance +
          petrolMaintenance +
          petrolTaxes +
          petrolVariable;
      },
    );
  }

  return {
    evPrice,
    petrolPrice,
  };
};
